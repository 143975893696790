import * as React from "react";
import { AboutModal, Text, TextContent, TextList, TextListItem } from "@patternfly/react-core";

import ShoutOut from "../assets/brands/ShoutOut Light.png";
import AltronBrand from "../assets/images/AltronLogoLight.png";

export const AboutModalComponent = ({ isOpen, toggleOpen }) => {

  return (
    <AboutModal
      isOpen={isOpen}
      onClose={(e) => toggleOpen(e)}
      trademark="&copy; Copyright Altron 2024"
      backgroundImageSrc={AltronBrand}
      brandImageSrc={ShoutOut}
      brandImageAlt="Company Logo"
      productName="ShoutOuts"
      hasNoContentContainer={false}
      style={{ backgroundPosition: 'center'}}
    >
      <TextContent>
        <Text component="p">
          Giving employees and fellow team members recognition for their hard work is a great way to let them 
          feel valued and appreciated in the workplace, which encourages them to perform at their best and
          increases productivity. The ShoutOut Application is designed to make recognition easy. It gets team
          members connected, engaged and show members of the team their hard work is recognized and appreciated.
        </Text>
        <Text component="p">&nbsp;</Text>
        <TextList component="dl">
          <TextListItem component="dt">Version</TextListItem>
          <TextListItem component="dd">2024.11</TextListItem>
          <TextListItem component="dt">Build</TextListItem>
          <TextListItem component="dd">003</TextListItem>
        </TextList>
      </TextContent>
    </AboutModal>
  );

};