import * as React from "react";
import { Button, Dropdown, DropdownItem, DropdownList } from "@patternfly/react-core";
import { EmptyState, EmptyStateActions, EmptyStateBody, EmptyStateFooter, EmptyStateHeader, EmptyStateIcon, EmptyStateVariant } from "@patternfly/react-core";
import { MenuToggle } from "@patternfly/react-core";
import { NotificationDrawer, NotificationDrawerBody, NotificationDrawerHeader, NotificationDrawerList } from "@patternfly/react-core";
import { Spinner } from "@patternfly/react-core";
import { EllipsisVIcon, SearchIcon } from "@patternfly/react-icons";

import { NotificationContext } from "./common/contexts/NotificationContext";
import { NotificationItem } from "./NotificationItem";

import "./Notifications.css";

export const Notifications = ({ update, drawerRef, getNumberUnread, onCloseDrawer, refresh, loading }) => {

  const { notifications } = React.useContext(NotificationContext);

  const [shouldShowNotifications, setShouldShowNotifications] = React.useState(true);
  const [isActionsMenuOpen, setIsActionsMenuOpen] = React.useState({});

  const onClickMarkAllRead = () => {
    notifications.forEach((item) => {
      item.has_seen_YN = 1;
      update(item);
    })
  };

  const showNotifications = (showNotifications) => {
    notifications.forEach((item) => {
      item.has_interacted_YN = (showNotifications ? 1 : 0);
      update(item);
    })
    setShouldShowNotifications(showNotifications);
  };

  const onRefresh = () => {
    refresh();
    setShouldShowNotifications(true);
  };

  const onToggle = (id) => {
    setIsActionsMenuOpen({ [id] : !isActionsMenuOpen[id] });
  };

  const closeActionsMenu = () => {
    setIsActionsMenuOpen({});
  };

  const notificationDrawerActions = (
    <>
      <DropdownItem key="markAllRead" onClick={onClickMarkAllRead}>Mark all read</DropdownItem>
      <DropdownItem key="clearAll" onClick={() => showNotifications(false)}>Clear all</DropdownItem>
      <DropdownItem key="unclearLast" onClick={() => showNotifications(true)}>Unclear last</DropdownItem>
      <DropdownItem key="settings">Settings</DropdownItem>
    </>
  );

  return (
    <NotificationDrawer ref={drawerRef}>
      <NotificationDrawerHeader count={getNumberUnread()} onClose={onCloseDrawer} customText="Not Implemented Yet">
        <Dropdown
          onSelect={closeActionsMenu}
          isOpen={isActionsMenuOpen['toggle-id-0'] || false}
          id="notification-0"
          onOpenChange={(isOpen) => !isOpen && closeActionsMenu()}
          popperProps={{ position: 'right' }}
          toggle={(toggleRef) => (
            <MenuToggle
              id="toggle-id-0"
              aria-label="Notifications Drawer Actions"
              isExpanded={isActionsMenuOpen['toggle-id-0'] || false}
              ref={toggleRef}
              variant="plain"
              onClick={() => onToggle('toggle-id-0')}
            >
              <EllipsisVIcon aria-hidden="true" />
            </MenuToggle>
          )}
        >
          <DropdownList>
            {
              notificationDrawerActions
            }
          </DropdownList>
        </Dropdown>
      </NotificationDrawerHeader>
      <NotificationDrawerBody>
        {
          loading ? (
            <EmptyState variant={EmptyStateVariant.full}>
              <Spinner size="xl" />
            </EmptyState>
          ) : shouldShowNotifications ? (
            <NotificationDrawerList>
              {
                 notifications.map((item) => (
                  <NotificationItem key={item.id} notification={item} update={update} />
                 ))
              }
            </NotificationDrawerList>
          ) : (
            <EmptyState variant={EmptyStateVariant.full}>
              <EmptyStateHeader
                headingLevel="h2"
                titleText="No notifications found"
                icon={<EmptyStateIcon icon={SearchIcon} />}
              />
              <EmptyStateBody>
                There are currently no notifications for you.
              </EmptyStateBody>
              <EmptyStateFooter>
                <EmptyStateActions>
                  <Button variant="link" onClick={onRefresh}>Refresh</Button>
                </EmptyStateActions>
              </EmptyStateFooter>
            </EmptyState>
          )
        }
      </NotificationDrawerBody>
    </NotificationDrawer>
  );

};