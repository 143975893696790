import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import { get } from "./api/api";
import { useAuth } from "./common/contexts/AuthContext";
import { NotificationProvider } from "./common/contexts/NotificationContext";
import { OrganizationContext, UserContext } from "./common/contexts";
import { localLog } from "./utils/Utilities";
import { LoadingStateComponent } from "./components/LoadingStateComponent";
import { AppLayout } from "./AppLayout";
import { Routing } from "./Routing";
import { Login } from "./pages/Login";

export const AppContent = () => {

  const { authState, setAuthState, login } = useAuth();

  const [debug] = React.useState(false);
  const [userProfileData, setUserProfileData] = React.useState({});
  const [organization, setOrganization] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        if (!authState || !authState.idToken) {
          setLoading(false);
          return;
        }
        const decodedToken = await get(1, "/auth/profile");
        // localLog("decodedToken: " + JSON.stringify(decodedToken, null, 2));

        let userProfileResponse = {};
        try {
          userProfileResponse = await get(1, `/users/email/getProfileByEmail?email=${decodedToken.data.identities[0].userId}`);
        } catch (error) {
          console.error(JSON.stringify(error));
          // This line ensures that you can login with an e-mail address
          userProfileResponse = await get(decodedToken.data.organization_id, `/users/${decodedToken.data.userId}`);
        }
        if (debug) localLog("userProfileResponse: " + JSON.stringify(userProfileResponse.data, null, 2));
        let roleResponse = [];
        try {
          roleResponse = await get(decodedToken.data.organization_id, `/role/getRoleWithPermissionsById/${userProfileResponse.data.role_id}`)
          if (debug) localLog("roleResponse.data: " + JSON.stringify(roleResponse.data));
        } catch (error) {
          console.error(JSON.stringify(error));
        }
        userProfileResponse.data.permissions = roleResponse.data.permissions;
        if (debug) localLog("userProfileResponse.data: " + JSON.stringify(userProfileResponse.data));
        let organizationResponse = {};
        try {
          organizationResponse = await get(userProfileResponse.data.organization_id, "");
        } catch (error) {
          console.error(JSON.stringify(error));
          // organizationResponse = await get(decodedToken.data.organization_id, "");
        }

        setUserProfileData(userProfileResponse.data);
        setOrganization(organizationResponse.data);
        setLoading(false);
      } catch (error) {
        console.error(JSON.stringify(error));
        setAuthState(null);
        setLoading(false);
      }
    };

    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState, setAuthState]);

  if (loading) {
    return (
      <LoadingStateComponent />
    );
  }

  return (
    <div className="App">
      <BrowserRouter>
        {
          !authState || !authState.idToken 
          ? (
              <Login login={login} />
          )
          : (
            <OrganizationContext.Provider value={organization}>
              <UserContext.Provider value={userProfileData}>
                <NotificationProvider>
                  <AppLayout user={userProfileData} organisation={organization}>
                    <Routing
                      userProfileData={userProfileData}
                      organization={organization}
                    />
                  </AppLayout>
                </NotificationProvider>
              </UserContext.Provider>
            </OrganizationContext.Provider>
          )
        }
      </BrowserRouter>
    </div>
  );
};
