import * as React from "react";
import { ActionGroup, Breadcrumb, BreadcrumbItem, Button } from "@patternfly/react-core";
import { Form, FormGroup, FormHelperText/*, FormSelect*//*, FormSelectOption*/ } from "@patternfly/react-core";
import { HelperText, HelperTextItem, Icon } from "@patternfly/react-core";
import { PageBreadcrumb, PageGroup, PageSection, Panel, PanelMain, PanelMainBody } from "@patternfly/react-core";
import { Stack, StackItem, Text, TextArea/*, Tooltip*/ } from "@patternfly/react-core";

import { BullhornIcon } from "@patternfly/react-icons";

import { PageHeadingComponent } from "../components/PageHeadingComponent";
import { SelectUsersComponent } from "../components/SelectUsersComponent";
// import { SingleFileUploadComponent } from "../components/SingleFileUploadComponent";

import ExternalUserData from "../components/ExternalUserData";
// import FileUpload from "./FileUpload";
import { CategorySelectionComponent } from "../components/CategorySelectionComponent";
// import MessageInput from "./MessageInput";
import { validationSchema } from "./validationSchema";
import { get, post } from "../api/api";
import * as Yup from "yup";
import { NotificationContext } from "../common/contexts/NotificationContext";
import { OrganizationContext } from "../common/contexts/OrganizationContext";
import { localLog } from "../utils/Utilities";

import "./SendShoutOut.css";

export const SendShoutOut = ({ userProfileData }) => {

  const { addNotification } = React.useContext(NotificationContext);
  const organization = React.useContext(OrganizationContext);
  const organizationId = organization.id;

  const [isExternal/*, setIsExternal*/] = React.useState(false);
//  const [userList, setUserList] = React.useState([]);
  const [selectedUsers, setSelectedUsers] = React.useState([]);
//  const [searchQuery, setSearchQuery] = React.useState("");

  const [categories, setCategories] = React.useState([]);
  const [selectedCategories, setSelectedCategories] = React.useState([]);

  const [message, setMessage] = React.useState("");
//   const [messageValidated, setMessageValidated] = React.useState("default");
  const [messageHelperText, setMessageHelperText] = React.useState("0/255 characters");

  // const [pictureFilename, setPictureFilename] = React.useState("");
  const [pictureFiledata, setPictureFiledata] = React.useState(null);
  // const [documentFilename, setDocumentFilename] = React.useState("");
  const [documentFiledata, setDocumentFiledata] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [extUserName, SetExtUserName] = React.useState("");
  const [extUserSurname, SetExtUserSurname] = React.useState("");
  const [extUserOrganization, SetExtUserOrganization] = React.useState("");

  const onMessageChange = (value) => {
    if (value.length > 0) {
      if (value.length === 1) {
        setMessage(value);
        setMessageHelperText("1/255 character");
      } else {
        setMessage(value);
        setMessageHelperText(`${value.length}/255 characters`);
      }
    } else {
      setMessage(value);
      setMessageHelperText("0/255 characters");
    }
  };
    
  // const handleIsExternalChange = (event) => {
  //   setIsExternal(event.target.value === "true");
  // };

  const handleExtUsernamechange = (event) => {
    const { value } = event.target;
    SetExtUserName(value);
  };

  const handleExtUsersurnamechange = (event) => {
    const { value } = event.target;
    SetExtUserSurname(value);
  };

  const handleExtUserOrganizationchange = (event) => {
    const { value } = event.target;
    SetExtUserOrganization(value);
  };

  // Get shoutout categories
  React.useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await get(organizationId, `/shoutoutcategory/getCategories?organizationId=${organization.id}`);
        setCategories(response.data);
        localLog("Here are the categories ", categories);
      } catch (error) {
        console.error(JSON.stringify(error));
      }
    };

    fetchCategories();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

//  const onSearchInputChange = (event) => {
//    setSearchQuery(event.target.value);
//  };

//  const onUserSelect = (user) => {
//    setSelectedUsers((prevUsers) => {
//      if (!prevUsers.some((u) => u.id === user.id)) {
//        return [...prevUsers, user];
//      }
//      return prevUsers;
//    });
//    setSearchQuery("");
//  };

//  const onUserRemove = (userToRemove) => {
//    setSelectedUsers((prevUsers) =>
//      prevUsers.filter((user) => user !== userToRemove),
//    );
//  };

  const toggleCategorySelection = (event, category) => {
    if (selectedCategories.some((c) => c.id === category.id)) {
      setSelectedCategories(selectedCategories.filter((c) => c.id !== category.id));
    } else {
      if (selectedCategories.length < 3) {
        setSelectedCategories([...selectedCategories, category]);
      }
    }
  };

  // input data validation
  const schema = validationSchema(isExternal);

  // Form submission
  const handleSubmit = async () => {
    const attachements = [pictureFiledata, documentFiledata];
    const filesUrls = [];
    try {
      // validate data
      await schema.validate(
        {
          isExternal,
          extUserName,
          extUserSurname,
          extUserOrganization,
          selectedUsers,
          selectedCategories,
          message,
        },
        {
          abortEarly: false
        },
      );
      if (attachements.length > 0) {
        // for (const attachement of attachements) {
        //   const url = await uploadAttachment(attachement);
        //   filesUrls.push(url);
        // }
      }
      for (let i = 0; i < selectedUsers.length; i++) {
        const shoutoutData = {
          organization_id: userProfileData ? userProfileData.organization_id : "", // to be changed for external users
          from_user_id: userProfileData ? userProfileData.id : null,
          to_user_id: selectedUsers[i].id,
          app_submission_YN: 1,
          external_shoutout_YN: isExternal ? 1 : 0,
          comment: message,
          category_1_id: selectedCategories[0].id,
          category_2_id: selectedCategories.length >= 2 ? selectedCategories[1].id : null,
          category_3_id: selectedCategories.length >= 3 ? selectedCategories[2].id : null,
          external_user_full_name: isExternal ? extUserName + " " + extUserSurname : null,
          external_user_organization: isExternal ? extUserOrganization : null,
          attachementsUrls: filesUrls,
        };
        await sendaShoutout(shoutoutData);
        addNotification({ variant: 'success', title: 'Success', description: `You have successfully sent a ShoutOut to ${selectedUsers[i].first_name} ${selectedUsers[i].surname}.` });
      }
      // Clear inputs
      setSelectedUsers([]);
      SetExtUserName("");
      SetExtUserSurname("");
      SetExtUserOrganization("");
      setSelectedCategories([]);
      setMessage("");
      onMessageChange("");
      setPictureFiledata(null);
      setDocumentFiledata(null);
      setErrorMessage([]);
    } catch (error) {
      console.error(JSON.stringify(error));
      if (error instanceof Yup.ValidationError) {
        // Check if error is an instance of Yup's ValidationError
        const errors = error.inner.reduce((acc, curr) => {
          acc[curr.path] = curr.message;
          return acc;
        }, {});
        console.error("Errors:", errors);
        setErrorMessage(errors);
      } else {
        // Handle other types of errors
        console.error("Non-validation error:", error);
      }
    }
  };

  // // Upload files
  // const uploadAttachment = async (file) => {
  //   // Create FormData object
  //   const formData = new FormData();
  //   formData.append("file", file);
  //   // upload file
  //   try {
  //     const response = await post(organizationId, `/shoutoutAttachmentAPI/upload`, formData, { headers: { "Content-Type": "multipart/form-data" }});
  //     return response.data.fileUrl;
  //   } catch (error) {
  //     console.error(JSON.stringify(error));
  //   }
  // };

  // Sending a shoutout
  const sendaShoutout = async (shoutoutData) => {
    try {
      await post(organizationId, `/shoutout/send`, shoutoutData);
    } catch (error) {
      console.error(JSON.stringify(error));
    }
  };

//  const [value, setValue] = React.useState("");
  const [hint, setHint] = React.useState("");
//  const [autocompleteOptions, setAutocompleteOptions] = React.useState([]);
  const [isAutocompleteOpen, setIsAutocompleteOpen] = React.useState(false);
  const searchInputRef = React.useRef(null);
  const autocompleteRef = React.useRef(null);

// const onClear = () => {
//   setValue("");
// };

//   const onChange = async (_event, newValue) => {
//     setSearchQuery(newValue);
//     await fetchUserList(newValue);
//     localLog("onChange(e, value)  value: " + newValue);
//     if (newValue !== "" && searchInputRef && searchInputRef.current && searchInputRef.current.contains(document.activeElement)) {
//       setIsAutocompleteOpen(true);
//       // When the value of the search input changes, build a list of no more than 10 autocomplete options.
//       // Options which start with the search input value are listed first, followed by options which contain the search input value.
//       let options = userList /* words */
// //        .filter((option) => option.email_address.startsWith(newValue.toLowerCase()))
//         .map((option) => (<MenuItem itemId={option.id} key={option.id}>{option.first_name + " " + option.surname}</MenuItem>));
//       if (options.length > 10) {
//         options = options.slice(0, 10);
// //      } else {
// //        options = [
// //          ...options,
// //          /* ...words */
// //          ...userList
// //            .filter((option) => !option.email_address.startsWith(newValue.toLowerCase()) && option.email_address.includes(newValue.toLowerCase()))
// //            .map((option) => (<MenuItem itemId={option.id} key={option.id}>{option.first_name + " " + option.surname}</MenuItem>))
// //        ].slice(0, 10);
//       }
//       // The hint is set whenever there is only one autocomplete option left.
//       setHint(options.length === 1 ? options[0].props.itemId : "");
//       // The menu is hidden if there are no options
//       setIsAutocompleteOpen(options.length > 0);
//       setAutocompleteOptions(options);
//     } else {
//       setIsAutocompleteOpen(false);
//     }
//     setValue(newValue);
//   };

  // // Whenever an autocomplete option is selected, set the search input value, close the menu and focus back on the search input
  // const onSelect = (e, itemId) => {
  //   e.stopPropagation();
  //   setValue(itemId);
  //   setIsAutocompleteOpen(false);
  //   searchInputRef.current.focus();
  // };

  const handleMenuKeys = (event) => {
    // If there is a hint while the browser focus is on the search input, tab or right arrow will 'accept' the hint value and set it as the search input value
    if (hint && (event.key === 'Tab' || event.key === 'ArrowRight') && searchInputRef.current === event.target) {
//      setValue(hint);
      setHint("");
      setIsAutocompleteOpen(false);
      if (event.key === 'ArrowRight') {
        event.preventDefault();
      }
      // If the autocomplete is open and the browser focus is on the search input
    } else if (isAutocompleteOpen && searchInputRef.current && searchInputRef.current === event.target) {
      // The escape key closes the autocomplete menu and keeps the focus on the search input
      if (event.key === 'Escape') {
        setIsAutocompleteOpen(false);
        searchInputRef.current.focus();
        // the up and down arrow keys move browser focus into the autocomplete menu
      } else if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
        const firstElement = autocompleteRef.current.querySelector('li > button:not(:disabled)');
        firstElement && firstElement.focus();
        event.preventDefault();  // by default, the up and down arrow keys scroll the window
        // The Tab, Enter and Space keys will close the menu, and the Tab key will move browser focus forward one element (by default)
      } else if (event.key === 'Tab' || event.key === 'Enter' || event.key === 'Space') {
        setIsAutocompleteOpen(false);
        if (event.key === 'Enter' || event.key === 'Space') {
          event.preventDefault();
        }
      }
      // If the autocomplete is open and the browser focus is in the autocomplete menu
      // hitting Tab will close the autocomplete and put browser focus back on the search input.
    } else if (isAutocompleteOpen && autocompleteRef.current.contains(event.target) && event.key === 'Tab') {
      event.preventDefault();
      setIsAutocompleteOpen(false);
      searchInputRef.current.focus();
    }
  };
  // The autocomplete menu should close if the user clicks outside the menu
  const handleClickOutside = (event) => {
    if (isAutocompleteOpen && autocompleteRef && autocompleteRef.current && !autocompleteRef.current.contains(event.target)) {
      setIsAutocompleteOpen(false);
    }
  };
  React.useEffect(() => {
    window.addEventListener('keydown', handleMenuKeys);
    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('keydown', handleMenuKeys);
      window.removeEventListener('click', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAutocompleteOpen, hint]);

  // const searchInput = (
  //   <SearchInput
  //     id="autocomplete-search"
  //     hint={hint}
  //     ref={searchInputRef}
  //     value={value}
  //     onChange={onChange}
  //     onClear={onClear}
  //   />
  // );

  // const autocomplete = (
  //   <Menu ref={autocompleteRef} onSelect={onSelect}>
  //     <MenuContent>
  //       <MenuList>{ autocompleteOptions }</MenuList>
  //     </MenuContent>
  //   </Menu>
  // );

  return (
    <>
      <PageGroup stickyOnBreakpoint={{ default: 'top' }}>
        <PageBreadcrumb>
          <Breadcrumb>
            <BreadcrumbItem to="/">Dashboard</BreadcrumbItem>
            <BreadcrumbItem isActive>Send A ShoutOut</BreadcrumbItem>
          </Breadcrumb>
        </PageBreadcrumb>
        <PageHeadingComponent title="Send A ShoutOut" subtitle="Recognize your colleagues by sending them a ShoutOut" icon={<Icon><BullhornIcon /></Icon>} />
      </PageGroup>
      <PageSection>
        <Stack hasGutter>
          <StackItem isFilled>
            <Panel>
              <PanelMain>
                <PanelMainBody>
                  <Form isHorizontal>
                    {/* <FormGroup label="ShoutOut Type" isRequired fieldId="type">
                      <Tooltip content={isExternal ? "Sender is a client of the receiver and is from outside the organisation e.g. Vodacom, Massmart, CCBA, etc." : "Sender is within the same organisation as the receiver" }>
                      <FormSelect id="type" onChange={handleIsExternalChange} value={isExternal.toString()} validated={!errorMessage.isExternal}>
                        <FormSelectOption key={"true"} value={"true"} label={"External"} description="External" />
                        <FormSelectOption key={"false"} value={"false"} label={"Internal"} description="Internal" />
                      </FormSelect>
                      </Tooltip>
                      <FormHelperText><HelperText><HelperTextItem variant="error">{ errorMessage.isExternal }</HelperTextItem></HelperText></FormHelperText>
                      <FormHelperText><HelperText><HelperTextItem variant="validated"><Text component="small">{ isExternal ? "Sender is a client of the receiver and is from outside the organisation e.g. Vodacom, Massmart, CCBA, etc." : "Sender is within the same organisation as the receiver" }</Text></HelperTextItem></HelperText></FormHelperText>
                    </FormGroup> */}
                    {
                      isExternal && (
                        <ExternalUserData
                          extUserName={extUserName}
                          extUserSurname={extUserSurname}
                          extUserOrganization={extUserOrganization}
                          onExtUserNameChange={handleExtUsernamechange}
                          onExtUserSurnameChange={handleExtUsersurnamechange}
                          onExtUserOrganizationChange={handleExtUserOrganizationchange}
                          nameErrorMessage={errorMessage.extUserName}
                          surnameErrorMessage={errorMessage.extUserSurname}
                          organizationErrorMessage={errorMessage.extUserOrganization}
                        />
                      )
                    }
                    <FormGroup label="To" isRequired>
                      <SelectUsersComponent selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} placeholder="" />
                      <FormHelperText><HelperText><HelperTextItem variant={errorMessage.selectedUsers ? "error" : "default"}><Text component="small">{errorMessage.selectedUsers ? errorMessage.selectedUsers : "Send recognition to your team by searching and adding multiple team members in this field" }</Text></HelperTextItem></HelperText></FormHelperText>
                    </FormGroup>
                    <CategorySelectionComponent
                      categories={categories}
                      selectedCategories={selectedCategories}
                      toggleCategorySelection={toggleCategorySelection}
                      errorMessage={errorMessage.selectedCategories}
                      isRequired={true}
                    />
                    <FormGroup
                      label="Message"
                      fieldId="message"
                      isRequired
                    >
                      <FormHelperText>
                        <HelperText style={{ float: 'right' }}>
                          <HelperTextItem variant={'default'}>
                            <Text component="small">{ messageHelperText }</Text>
                          </HelperTextItem>
                        </HelperText>
                      </FormHelperText>
                      <TextArea id="message" isRequired value={message} rows={3} onChange={(event, value) => onMessageChange(value)} autoResize={true} resizeOrientation="none"></TextArea>
                      <FormHelperText>
                        <HelperText>
                          <HelperTextItem variant={errorMessage.message ? "error" : "default"}>
                            <Text component="small">{ errorMessage.message ? errorMessage.message : "" }</Text>
                          </HelperTextItem>
                        </HelperText>
                      </FormHelperText>
                    </FormGroup>
                    {/* <FormGroup label="Add a Picture" fieldId="picture">
                      <SingleFileUploadComponent
                        id="picture"
                        accept={{ "image/*": ['.png'] }}
                        filename={pictureFilename}
                        setFilename={setPictureFilename}
                        value={pictureFiledata}
                        setValue={setPictureFiledata}
                      />
                    </FormGroup>
                    <FormGroup label="Supporting Document" fieldId="document">
                      <SingleFileUploadComponent
                        id="document"
                        accept={{ "application/x-compressed": ['.csv', '.doc', '.docx', '.pdf', '.ppt', '.pptx', '.txt', '.xls', '.xlsx'] }}
                        filename={documentFilename}
                        setFilename={setDocumentFilename}
                        value={documentFiledata}
                        setValue={setDocumentFiledata}
                      />
                    </FormGroup> */}
                    <ActionGroup>
                      <Button variant="primary" onClick={handleSubmit}>Submit</Button>
                      <Button component="a" href="/" variant="link">Cancel</Button>
                    </ActionGroup>
                  </Form>
                </PanelMainBody>
              </PanelMain>
            </Panel>
          </StackItem>
        </Stack>
      </PageSection>
    </>
  );
};
