import * as React from "react";
import { FormGroup, FormHelperText, HelperText, HelperTextItem, Text, Tile, Tooltip } from "@patternfly/react-core";

export const CategorySelectionComponent = ({ categories, selectedCategories, toggleCategorySelection, isRequired, errorMessage }) => {

  return (
    <>
      <FormGroup label="Category" isRequired={isRequired}>
        <div role="listbox" aria-label="Stacked tiles with large icons">
          {
            categories.map((category) => (
              <Tooltip content={category.description} key={category.id}>
                <Tile
                  id={category.id}
                  key={category.id}
                  icon={<img src={category.icon_url.startsWith("https://") ? category.icon_url : `https://shoutout-file-uploads.s3.af-south-1.amazonaws.com/${category.icon_url}`} alt={`${category.name}`} style={{ height: '24px' }} />}
                  title={category.name}
                  isStacked
                  isSelected={selectedCategories.includes(category)}
                  onClick={(event, cat) => { toggleCategorySelection(event, category)}}
                >
                  {category.points} Points
                </Tile>
              </Tooltip>
            ))
          }
        </div>
        <FormHelperText><HelperText><HelperTextItem variant={errorMessage ? "error" : "default"}><Text component="small">{ errorMessage ? errorMessage : "You can choose up to a maximum of 3 categories" }</Text></HelperTextItem></HelperText></FormHelperText>
      </FormGroup>
    </>
  );

};
