import * as React from "react"
import { Avatar } from "@patternfly/react-core";

export const AvatarComponent = ({ user, size, style }) => {

  return (
    <Avatar
      src={user?.profile_picture_url?.startsWith('https://shoutout-file') ? user?.profile_picture_url : "https://ui-avatars.com/api/?name=" + user?.first_name + "+" + user?.surname}
      alt={user?.profile_picture_url}
      size={`${size}`}
      style={style}
    />
  );

};