import * as React from "react";
import { Button, Form, FormGroup, FormHelperText, HelperText, HelperTextItem, Modal, ModalVariant, Text, TextArea } from "@patternfly/react-core";

export const ShoutOutCommentModal = ({ title, message, comment, setComment, isOpen, isDelete, onClose, onAction }) => {

  const [errors] = React.useState({});
  const [commentHelperText, setCommentHelperText] = React.useState(`${comment.length}/255 characters`);

  const onCommentChange = (value) => {
    if (value.length > 0) {
      if (value.length === 1) {
        setComment(value);
        setCommentHelperText("1/255 character");
      } else {
        setComment(value);
        setCommentHelperText(`${value.length}/255 characters`);
      }
    } else {
      setComment(value);
      setCommentHelperText("0/255 characters");
    }
  };
    
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      titleIconVariant={isDelete ? "warning" : "info"}
      description={message}
      variant={ModalVariant.medium}
      actions={[
        <Button key="action" variant={isDelete ? "danger" : "primary"} onClick={onAction}>
          {isDelete ? "Delete" : "Save"}
        </Button>,
        <Button key="cancel" variant="link" onClick={onClose}>
          Cancel
        </Button>
      ]}
    >
      <Form>
        <FormGroup
          fieldId="comment"
          label="Comment"
          isRequired
        >
          <TextArea
            id="comment"
            autoFocus
            autoResize={true} 
            isRequired
            placeholder="Write a comment..."
            resizeOrientation="none"
            rows={3}
            value={comment}
            onChange={(e) => onCommentChange(e.target.value)}
          />
          <FormHelperText>
            <HelperText style={{ float: 'right' }}>
              <HelperTextItem variant={'default'}>
                <Text component="small">{ commentHelperText }</Text>
              </HelperTextItem>
            </HelperText>
            <HelperText>
              <HelperTextItem variant={ Object.keys(errors).length > 0 ? "error" : "default"}>
                <Text component="small">{ errors.comment }</Text>
              </HelperTextItem>
            </HelperText>
          </FormHelperText>
        </FormGroup>
      </Form>
    </Modal>
  );
};