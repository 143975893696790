import * as React from "react";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Button } from "@patternfly/react-core";
import { Icon } from "@patternfly/react-core";
import { PageBreadcrumb, PageGroup, PageSection, PageSectionVariants, Pagination } from "@patternfly/react-core";
import { Toolbar, ToolbarContent, ToolbarGroup, ToolbarItem, Tooltip } from "@patternfly/react-core";
import { DownloadIcon, HomeIcon, SyncIcon } from "@patternfly/react-icons";

import io from "socket.io-client";
import { get } from "../../api/api";
import { hasReportsPermission } from "../../utils/Utilities";
import { OrganizationContext } from "../../common/contexts/OrganizationContext";
import { CompetitionToolbarComponent } from "../../components/CompetitionToolbarComponent";
// import { DateRangeToolbarComponent } from "../../components/DateRangeToolbarComponent";
import { LoadingStateComponent } from "../../components/LoadingStateComponent";
import { NoResultsFoundComponent } from "../../components/NoResultsFoundComponent";
import { PageHeadingComponent } from "../../components/PageHeadingComponent";
import { ShoutOutCards } from "./ShoutOutCards";
import { ShoutOutDataList } from "./ShoutOutDataList";
import * as XLSX from "xlsx";

const socket = io(process.env.REACT_APP_SERVER_URL);

export const ShoutOuts = ({ user, shoutoutsFromMe, shoutoutsToMe }) => {

  const organization = React.useContext(OrganizationContext);
  const organizationId = organization.id;

  // const [debug] = React.useState(false);
  const [pageTitle, setPageTitle] = React.useState("Latest ShoutOuts");
  const [competition, setCompetition] = React.useState(undefined);
  const [shoutouts, setShoutouts] = React.useState([]);
  const [hasMore, setHasMore] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [view] = React.useState("CardsView");
//  const [departments, setDepartments] = React.useState([]);
//  const [departmentSelected, setDepartmentSelected] = React.useState("");
//  const [departmentIsExpanded, setDepartmentIsExpanded] = React.useState(false);
  const [initialLoad, setInitialLoad] = React.useState(true);
  const [limit, setLimit] = React.useState(10);

  // Format date
  const formatDate = (date) => {
    if (date === null) {
      date = new Date();
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Set default date for date pickers
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);

  const onSetPage = (_event, newPage) => {
    setPage(newPage);
  };

  const onPerPageSelect = (_event, newPerPage, newPage) => {
    setLimit(newPerPage);
    setPage(newPage);
  };

  // const onViewClick = (event, _isSelected) => {
  //   const id = event.currentTarget.id;
  //   setView(id);
  // };

  // const { isOpen, onOpen, onClose } = useDisclosure();

  // const onDepartmentToggle = () => {
  //   setDepartmentIsExpanded(!departmentIsExpanded);
  // };

  // const onDepartmentSelect = (_event, selection) => {
  //   localLog("selection: " + JSON.stringify(selection));
  //   setDepartmentSelected(selection);
  //   setDepartmentIsExpanded(false);
  // };

  React.useEffect(() => {
    fetchShoutouts();
    setInitialLoad(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  // const fetchCompetitions = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await get(organization.id, `/scoringPeriod/getAllScoringPeriods`);
  //     if (debug) localLog("response: " + JSON.stringify(response, null, 2));
  //     setCompetitions(response.data.scoringPeriods);
  //     setLoading(false);
  //   } catch (error) {
  //     console.error(JSON.stringify(error));
  //     if (error?.status === 404) {
  //       setCompetitions([]);
  //       setLoading(false);
  //     } else {
  //       setLoading(false);
  //     }
  //   }
  // };

  React.useEffect(() => {

    const firstFetch = async() => {
      // fetchCompetitions();
    }

    firstFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // // Fetch departments
  // React.useEffect(() => {
  //   fetchDepartments();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  React.useEffect(() => {
    socket.on("new-comment", (updatedShoutout) => {
      setShoutouts((prevShoutouts) =>
        prevShoutouts.map((shoutout) =>
          shoutout.id === updatedShoutout.id ? updatedShoutout : shoutout
        )
      );
    });

    socket.on("new-like", (updatedShoutout) => {
      setShoutouts((prevShoutouts) =>
        prevShoutouts.map((shoutout) =>
          shoutout.id === updatedShoutout.id ? updatedShoutout : shoutout
        )
      );
    });

    return () => {
      socket.off("new-comment");
      socket.off("new-like");
    };
  }, []);

  const onDownloadReport = async () => {
    let endpoint = "";
    if (shoutoutsToMe) {
      endpoint = `/shoutout/getShoutOutsToMe/${user.id}/9999/0?startDate=${formatDate(startDate)} 00:00:00&endDate=${formatDate(endDate)} 23:59:59`;
    } else if (shoutoutsFromMe) {
      endpoint = `/shoutout/getShoutOutsFromMe/${user.id}/9999/0?startDate=${formatDate(startDate)} 00:00:00&endDate=${formatDate(endDate)} 23:59:59`;
    } else {
      endpoint = `/shoutout/getShoutOuts/${organizationId}/9999/0?startDate=${formatDate(startDate)} 00:00:00&endDate=${formatDate(endDate)} 23:59:59`;
    }
    const fileExtension = ".xlsx";
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };

    try {
      await get(organizationId, endpoint, requestOptions)
      .then((res) => {
        const data2 = res.data.map(item => ({
          "shoutout_id": item.id,
          "shoutout_organization_id": item.organization_id,
          "shoutout_date": item.submitted_date,
          "shoutout_text": item.comment,
          "shoutout_recipient_id": item.to_user_id,
          "shoutout_recipient_first_name": item.toUser?.first_name,
          "shoutout_recipient_surname": item.toUser?.surname,
          "shoutout_recipient_email": item.toUser?.email_address,
          "shoutout_recipient_department": item.toUser?.department?.name,
          "shoutout_sender_id": item.from_user_id,
          "shoutout_sender_first_name": item.fromUser?.first_name,
          "shoutout_sender_surname": item.fromUser?.surname,
          "shoutout_sender_email": item.fromUser?.email_address,
          "shoutout_sender_department": item.fromUser?.department?.name,
          "shoutout_category_1_id": item.category_1_id,
          "shoutout_category_1_name": item.category1?.name,
          "shoutout_category_1_description": item.category1?.description,
          "shoutout_category_1_points": item.category1?.points,
          "shoutout_category_2_id": item.category_2_id,
          "shoutout_category_2_name": item.category2?.name,
          "shoutout_category_2_description": item.category2?.description,
          "shoutout_category_2_points": item.category2?.points,
          "shoutout_category_3_id": item.category_3_id,
          "shoutout_category_3_name": item.category3?.name,
          "shoutout_category_3_description": item.category3?.description,
          "shoutout_category_3_points": item.category3?.points,
          "shoutout_likes": item.shoutout_likes?.length,
          "shoutout_comments": item.shoutout_comments?.length,
        }));
        const ws = XLSX.utils.json_to_sheet(data2);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Data");
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: 'array' });
        const href = window.URL.createObjectURL(new Blob([excelBuffer], { type: 'application/octet-stream' /*fileType*/ }));
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `ShoutOuts ${formatDate(startDate)} - ${formatDate(endDate)}${fileExtension}`);  // or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        return Promise.reject({ Error: 'Something Went Wrong', err });
      })
    } catch (error) {
      console.error(JSON.stringify(error));
    }
  };

  const fetchShoutouts = async (isNewFilter = false) => {
    setLoading(true);
    try {
      if (isNewFilter) {
        setShoutouts([]);
        setPage(1);
        setHasMore(true);
      }

      let baseEndpoint = "/shoutout";
      let offset = isNewFilter ? 0 : (page - 1) * limit;
      if (offset < 0) {
        offset = 0;
      }
      let endpoint = "";

      if (shoutoutsToMe) {
        endpoint = `${baseEndpoint}/getShoutOutsToMe/${user.id}/${limit}/${offset}?startDate=${formatDate(startDate)} 00:00:00&endDate=${formatDate(endDate)} 23:59:59`;
        setPageTitle("ShoutOuts To Me");
      } else if (shoutoutsFromMe) {
        endpoint = `${baseEndpoint}/getShoutOutsFromMe/${user.id}/${limit}/${offset}?startDate=${formatDate(startDate)} 00:00:00&endDate=${formatDate(endDate)} 23:59:59`;
        setPageTitle("ShoutOuts From Me");
      } else {
        endpoint = `${baseEndpoint}/getShoutOuts/${organizationId}/${limit}/${offset}?startDate=${formatDate(startDate)} 00:00:00&endDate=${formatDate(endDate)} 23:59:59`;
      }

      // if (departmentSelected) {
      //   endpoint += `&departmentId=${departmentSelected}`;
      // }

      // localLog("endpoint: " + JSON.stringify(endpoint, null, 2));

      if (endpoint) {
        const response = await get(organizationId, endpoint);
        const newShoutouts = response.data;

        if (newShoutouts.length < limit) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }

        setShoutouts(newShoutouts);
//        setShoutouts((prevShoutouts) => [...prevShoutouts, ...newShoutouts]);
//        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      console.error(JSON.stringify(error));
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    if (!initialLoad) {
      setLoading(true);
      const handler = setTimeout(() => {
        fetchShoutouts(true);
      }, 500);
      return () => {
        clearTimeout(handler);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, limit/*, departmentSelected*/]);

  // const fetchDepartments = async () => {
  //   try {
  //     const response = await get(organizationId, "/department/getDepartments?organizationId=" + organizationId);
  //     setDepartments(response.data);
  //   } catch (error) {
  //     console.error(JSON.stringify(error));
  //   }
  // };


  return (
    <>
      <PageGroup stickyOnBreakpoint={{ default: 'top' }}>
        <PageBreadcrumb>
          <Breadcrumb>
            <BreadcrumbItem isActive>Dashboard</BreadcrumbItem>
          </Breadcrumb>
        </PageBreadcrumb>
        <PageHeadingComponent title={pageTitle} subtitle="List of recent ShoutOuts." icon={<Icon><HomeIcon /></Icon>} />
        <PageSection variant={PageSectionVariants.light} padding={{ default: 'noPadding' }}>
          <Toolbar>
            <ToolbarContent>
{/*
              <ToolbarGroup>
                <ToolbarItem variant="label">
                  Department
                </ToolbarItem>
                <ToolbarItem>
                  <Select
                    toggle={(toggleRef) => (
                      <MenuToggle
                        ref={toggleRef}
                        onClick={() => onDepartmentToggle()}
                        isExpanded={departmentIsExpanded}
                        style={{ width: '180px' }}
                      >
                        {departments.find((item) => item.id === departmentSelected)?.name || "All Departments"}
                      </MenuToggle>
                    )}
                    onSelect={onDepartmentSelect}
                    onOpenChange={(isOpen) => setDepartmentIsExpanded(isOpen)}
                    selected={departmentSelected}
                    isOpen={departmentIsExpanded}
                  >
                    <SelectList>
                      <SelectOption key={0} value={0}>All Departments</SelectOption>
                      {
                        departments.map((department, index) => (
                          <SelectOption key={index} value={department.id} label={department.name}>
                            { department.name }
                          </SelectOption>
                        ))
                      }
                    </SelectList>
                  </Select>
                </ToolbarItem>
              </ToolbarGroup>
*/}
              <CompetitionToolbarComponent organizationId={organizationId} competition={competition} setCompetition={setCompetition} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
{/*
              <DateRangeToolbarComponent startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
*/}
{/*
              <ToolbarGroup>
                <ToolbarItem variant="separator" />
                <ToolbarItem>
                  <ToggleGroup>
                    <ToggleGroupItem aria-label="CardsView" buttonId="CardsView" icon={<ThIcon />} isSelected={view === "CardsView"} onChange={onViewClick} />
                    <ToggleGroupItem aria-label="DataListView" buttonId="DataListView" icon={<ListIcon />} isSelected={view === "DataListView"} onChange={onViewClick} />
                  </ToggleGroup>
                </ToolbarItem>
              </ToolbarGroup>
*/}
              <ToolbarGroup>
                <ToolbarItem variant="separator" />
                <ToolbarItem>
                  <Tooltip content="Create new ShoutOut">
                    <Link to="/send"><Button variant="primary">Create ShoutOut</Button></Link>
                  </Tooltip>
                </ToolbarItem>
              </ToolbarGroup>
              <ToolbarGroup>
                <ToolbarItem>
                  <Tooltip content="Refresh List">
                    <Button variant="plain" onClick={() => fetchShoutouts(false)}><Icon><SyncIcon /></Icon></Button>
                  </Tooltip>
                </ToolbarItem>
              </ToolbarGroup>
              {
                hasReportsPermission(user)
                ? (
                    <ToolbarGroup>
                      <ToolbarItem>
                        <Tooltip content="Download Report">
                          <Button variant="plain" onClick={() => onDownloadReport(false)}><Icon><DownloadIcon /></Icon></Button>
                        </Tooltip>
                      </ToolbarItem>
                    </ToolbarGroup>
                )
                : null
              }
              <ToolbarGroup align={{default: 'alignRight' }}>
                <Pagination
                  isCompact
                  toggleTemplate={({ firstIndex, lastIndex}) => (
                    <React.Fragment>
                      <b>{firstIndex} - {lastIndex}</b> of <b>many</b>
                    </React.Fragment>
                  )}
                  widgetId="shoutouts"
                  perPage={limit}
                  page={page}
                  onSetPage={onSetPage}
                  onPerPageSelect={onPerPageSelect}
                  itemsEnd={hasMore ? (page * limit) : ((page - 1) * limit) + shoutouts.length}
                  itemCount={hasMore ? (page * limit) + 1 : ((page - 1) * limit) + shoutouts.length}
                  variant="top"
                />
              </ToolbarGroup>
            </ToolbarContent>
          </Toolbar>
        </PageSection>
      </PageGroup>

      <PageSection isFilled>
      { 
          loading ?
          ( <LoadingStateComponent /> ) :
          ( shoutouts.length === 0 && !hasMore ?
            ( <NoResultsFoundComponent isFullHeight /> ) :
            ( (view === "DataListView") ?
              ( <ShoutOutDataList user={user} organization={organization} shoutouts={shoutouts} setShoutouts={setShoutouts} /> ) :
              ( <ShoutOutCards user={user} organization={organization} shoutouts={shoutouts} setShoutouts={setShoutouts} /> )
            )
          )
        }
      </PageSection>
      <PageSection
        isFilled={false}
        stickyOnBreakpoint={{ default: 'bottom' }}
        // padding={{ default: 'noPadding' }}
        variant="light"
      >
        <Pagination
          isCompact
          toggleTemplate={({ firstIndex, lastIndex}) => (
            <React.Fragment>
              <b>{firstIndex} - {lastIndex}</b> of <b>many</b>
            </React.Fragment>
          )}
          widgetId="shoutouts"
          perPage={limit}
          page={page}
          onSetPage={onSetPage}
          onPerPageSelect={onPerPageSelect}
          itemsEnd={hasMore ? (page * limit) : ((page - 1) * limit) + shoutouts.length}
          itemCount={hasMore ? (page * limit) + 1 : ((page - 1) * limit) + shoutouts.length}
        />
      </PageSection>
    </>
  );

};
