import * as React from "react";
import { Bullseye, Button } from "@patternfly/react-core";
import { Form, FormGroup, FormSelect, FormSelectOption } from "@patternfly/react-core";
import { Modal, ModalVariant, Spinner, TextInput } from "@patternfly/react-core";

import { get, put } from "../../api/api";
import { localLog } from "../../utils/Utilities";
import { ErrorStateComponent } from "../../components/ErrorStateComponent";
import { ManagerComponent } from "../../components/ManagerComponent";

export const UserModal = ({ isOpen, onClose, user, onUserUpdated, organizationId }) => {

  const [debug] = React.useState(false);
  const [state, setState] = React.useState("");
  const [error, setError] = React.useState(null);
  const [userData, setUserData] = React.useState(user ? user : undefined);

  const [department, setDepartment] = React.useState(null);
  // const [role, setRole] = React.useState(null);
  const [roles, setRoles] = React.useState([]);

  React.useEffect(() => {
    if (debug) localLog("user: " + JSON.stringify(user, null, 2));
//    if (user)
//      setUserData(user);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {

    const fetchDepartment = async (id) => {
      try {
        if (debug) localLog("fetchDepartment id: " + id);
        const response = await get(organizationId, `/department/getDepartment/${id}`);
        if (debug) localLog("department: " + JSON.stringify(response.data, null, 2));
        setDepartment(response.data);
      } catch (err) {
        console.error(JSON.stringify(err));
      }
    };

    const fetchRoles = async () => {
      try {
        const response = await get(organizationId, `/role/getAllRolesByOrganization`);
        if (debug) localLog("roles: " + JSON.stringify(response.data, null, 2));
        setRoles(response.data);
        if (debug) localLog("user: " + JSON.stringify(user, null, 2));
        // setRole(response.data.find((item) => { return item.id === user.role_id }));
      } catch (error) {
        console.error(JSON.stringify(error));
        setError(error);
      }
    };

    fetchRoles();
    if (user) {
      if (user.department_id) {
        fetchDepartment(user?.department_id);
      } else {
        console.error("Department not found");
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line no-unused-vars
  const onSave = async () => {
    setState("");
    try {
      const form = {
        "role_id": Number(userData ? userData.role_id : user.role_id)
      };
      if (debug) localLog("form: " + JSON.stringify(form));
      const response = await put(user.organization_id, `/users/${user.id}/role`, form);
      if (debug) localLog("response: " + JSON.stringify(response, null, 2));
      onUserUpdated(response);
    } catch (error) {
      console.error("Error Response: " + JSON.stringify(error.response));
      console.error(JSON.stringify(error));
      setError(error);
      setState("error");
    } finally {
      setState("");
    }
  };

  if (state === "loading") {
    return (
      <Modal
        aria-label="User Modal Loading"
        isOpen={isOpen}
        onClose={onClose}
        variant={ModalVariant.medium}
        title="Edit User"
      >
        <Bullseye style={{ minHeight: '200px' }}>
          <Spinner size="xl" />
        </Bullseye>
      </Modal>
    );
  } else if (state === "error" || !user) {
    return (
      <Modal
        aria-label="User Modal Error"
        isOpen={isOpen}
        onClose={onClose}
        variant={ModalVariant.medium}
        title="Edit User"
      >
        <ErrorStateComponent error={error} />
      </Modal>
    );
  }

  return (
    <Modal
      aria-label="User Modal"
      isOpen={isOpen}
      onClose={onClose}
      title="Edit User"
      variant={ModalVariant.medium}
      actions={[
        <Button key="save" variant="primary" form="role-form" onClick={onSave} isLoading={state === "saving"} isDisabled={state === "saving"}>Save</Button>,
        <Button key="cancel" variant="secondary" onClick={onClose}>Cancel</Button>
      ]}
    >
      <Form id="role-form" isHorizontal>
        <FormGroup fieldId="name" label="First Name" isRequired>
          <TextInput id="name" readOnlyVariant="plain" type="text" value={user.first_name || ""} onChange={(e) => setUserData({ ...userData, first_name: e.target.value })} />
        </FormGroup>
        <FormGroup fieldId="surname" label="Surname" isRequired>
          <TextInput id="surname" readOnlyVariant="plain" type="text" value={user.surname || ""} onChange={(e) => setUserData({ ...userData, surname: e.target.value })} />
        </FormGroup>
        <FormGroup fieldId="email" label="E-mail Address" isRequired>
          <TextInput id="email" readOnlyVariant="plain" type="email" value={user.email_address || ""} onChange={(e) => setUserData({ ...userData, email_address: e.target.value })} />
        </FormGroup>
        <FormGroup fieldId="department" label="Department">
          <TextInput id="department" readOnlyVariant="plain" type="text" value={department?.name || ""} onChange={(e) => setUserData({ ...userData, department_id: e.target.value })} />
        </FormGroup>
        <FormGroup fieldId="manager" label="Manager">
          <ManagerComponent user={user} />
          {/* <TextInput id="manager" readOnlyVariant="plain" type="text" value={user.managerName || ""} onChange={(e) => setUserData({ ...userData, managerName: e.target.value })} /> */}
        </FormGroup>
        <FormGroup fieldId="role" label="Role" isRequired>
          <FormSelect id="role" value={user?.role_id || undefined} onChange={(e, value) => setUserData({ ...userData, role_id: value })}>
            {
              roles.map((role) => (
                <FormSelectOption key={role.id} value={role.id} label={role.name} />
              ))
            }
          </FormSelect>
        </FormGroup>
      </Form>
    </Modal>
  );
};
